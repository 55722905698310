import { lazy, Suspense } from 'react'
import LoadingSplash from '../../components/util/LoadingSplash/LoadingSplash'

const loadable = importFunc => {
  const LazyComponent = lazy(importFunc)

  return props => (
    <Suspense fallback={<LoadingSplash />}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export default loadable
