import './LoadingSplash.css'

const LoadingSplash = () => {
  return (
    <div className='splash-logo-container'>
      <div className='splash-ball'></div>
      <h1>Loading</h1>
    </div>
  )
}

export default LoadingSplash
