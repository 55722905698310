import { useEffect } from 'react'
import {
  useChangeTheme,
  useIsDarkMode,
  useTheme,
} from '../../utils/functions/themeFunctions'

const ThemeWrapperObserver = ({ children }) => {
  const theme = useTheme()
  const changeTheme = useChangeTheme()
  const isDarkMode = useIsDarkMode()

  useEffect(() => {
    if (theme !== isDarkMode) {
      changeTheme(theme)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  return children
}

export default ThemeWrapperObserver
