import { useEffect, useState } from 'react'
import { useThemeSwitcher } from 'react-css-theme-switcher'

export const useTheme = () => {
  const [theme, setTheme] = useState(false)
  useEffect(() => {
    const themeDetect = e => {
      let newTheme = ''
      if (e.matches) {
        newTheme = true
      } else {
        newTheme = false
      }
      setTheme(newTheme)
    }
    const m = window.matchMedia('(prefers-color-scheme: dark)')
    setTheme(m.matches)
    m.addEventListener('change', themeDetect)
    return () => {
      m.removeEventListener('change', themeDetect)
    }
  }, [])
  return theme
}

export const isDarkMode = theme => theme === 'dark'

export const getUserTheme = () => {
  const m = window.matchMedia('(prefers-color-scheme: dark)')
  return m.matches ? 'dark' : 'light'
}

export const useIsDarkMode = () => {
  const { currentTheme } = useThemeSwitcher()
  return isDarkMode(currentTheme)
}

export const useChangeTheme = () => {
  const { switcher, themes } = useThemeSwitcher()
  return toDarkTheme => {
    switcher({ theme: toDarkTheme ? themes.dark : themes.light })
  }
}
