// import 'antd/dist/antd.css'
import { getUserTheme } from './utils/functions/themeFunctions'

import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import loadable from './utils/functions/loadable'

import 'bootstrap-4-grid/css/grid.min.css'
import './assets/css/app.css'
import ThemeWrapperObserver from './components/theme/ThemeWrapperObserver'

const ReduxApp = loadable(() => import('./redux/ReduxApp'))

const themes = {
  light: `${process.env.PUBLIC_URL}/antd-light.css`,
  dark: `${process.env.PUBLIC_URL}/antd-dark.css`,
}

function App() {
  const defaultTheme = getUserTheme()

  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={defaultTheme}>
      <ThemeWrapperObserver>
        <ReduxApp />
      </ThemeWrapperObserver>
    </ThemeSwitcherProvider>
  )
}

export default App
